
.mvn_container {
 background-color: #fff;

  h1{
  font-size: 3.0rem;
  font-weight: 900;
  }

  h2{
  font-size: 2.0rem;
  font-weight: 500;
  }

  h3{
  font-size: 1.2rem;
  font-weight: 500;
  }

  h1, h2, h3 {
    color: $maven;
  }

  h4, h5, h6 {
    color: $charcoal;
  }

  h1 {
        margin-left: 1rem;
        padding-top: 2rem;
        line-height: 1em;
      }
  
  a {
    color: $maven;
  }

  li {
    font-size: 1.2rem;
  }
  

  @media screen and (min-width: 40rem) {
    
  }
}


//*********************************


#mvnnav {
  top:0;
  left:0;
  padding-top:0;
  padding-bottom:0;
  margin: 0 100px 0 0;
  min-width: 100%;
  background: transparent;
  border: none;
  z-index:99;

  .top-bar-text{
    font-family: 'Raleway', Roboto, Arial, sans-serif;
    //font-size: 2em;
    color: $maven;
  }
 
  #top-bar{
    padding-top:0;
    padding-bottom:0;
  }

  .top-bar-container{
    min-width: 100%;
  }


  .top-bar{
    min-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background: transparent;
    opacity: 95%;
      
    .top-bar-left{
      max-width: 50%;
      padding-right: 5px;
      margin-right: 0px;
      float: left;
      vertical-align: baseline;
      img{
        width:85px;
      }
      

    }

    .top-bar-center{
      max-width:70%;
      width:70%;
      padding-right: 1rem;
      text-align: center;

      color: $maven;
      //font-size: 2rem;
      font-weight:300;
    }
     .top-bar-right{
      padding-top: 10px;
      background: transparent;
      opacity: 95%;
    }

    button{
      background-color: $maven;
      border-radius: 10px;
    }

    
    }

    @media screen and (min-width: 40rem) {
    position: relative; 

    .top-bar{
      .top-bar-left{
        max-width: 85px;
      }

    } 

    @media print {
       .top-bar{
        padding-top:0px;
        padding-bottom:0px;
        background-color: red;
        
        .top-bar-left{
        max-width: 80px;
        padding-right: 5px;
        margin-right: 0px;
        float: left;
        vertical-align: baseline;
        img{
          width:75px;
        }
    }
        }
    }
  }
}


#mvnForm{
  
}

.form_sneakpeak{
  width:100%; 
  padding:1rem 2rem 1rem 2rem;

  input[type="text"] {
    
    border:none;
    box-shadow: none;
    border-bottom: solid 1px $maven;
  }

   .button {
          width:40%;
          margin-top:3%;
          margin-right: 30%;
          margin-left: 30%;
          font-family: $header-font-family;
          font-size: 1rem;
          font-weight:900;
          background-color: $maven;
          border-radius:10px;
        }  
}


//*********************************


.sneak-peak-small{
  width:100%;
  .button{
    background-color: $maven;
    border-radius: 10px;
    padding:.4rem;
  }
}

//**************************************

.mvnInformation{
    padding-top: 20px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    min-height: 625px;

    h1{
      margin-left: 0;
      padding-top: 0;
      text-align: left;
      .centered {
        text-align: center;
      }
    }

    h2{
      font-size: 2.1 em;
      .centered {
        text-align: center;
      }
    }

    ul li {
      font-size: 1.1em;
    }

    .header1 {
      font-family: 'Raleway', Roboto, Arial, sans-serif;
      font-weight: 800;
      font-size: 3em;
      color: $jet;
      text-align: center; 
    }
    
    .header2 {
      font-family: 'Raleway', Roboto, Arial, sans-serif;
      font-weight: 400;
      font-size: 2.4em;
      color: $charcoal;
      text-align: center;  
    }
    
    img{
      border-radius: 10px;
    }

      @media screen and (min-width: 40rem) {
        padding-top: 40px;
   }

   .release {
    font-size: 0.8em;
    h4 {
      color:$maven;
    }

    h5 {
      color:$maven;
    }

    ul {
    }

    .release-note {
      font-size: 1.2em;
    }

    hr{
      border-top: 1px solid $maven;

      }
    }
  }

  .block_maven {
    background-color:rgba(152, 12, 129, 0.1);
    border-radius: 10px;
    padding: 30px;
    font-family: 'Raleway', Roboto, Arial, sans-serif;
    font-size: 1.3em;
    font-weight: 700;
  }

  .block_grey {
    background-color:rgba(136, 136, 136, 0.1);
    border-radius: 10px;
    padding: 30px;
    font-family: 'Raleway', Roboto, Arial, sans-serif;
    font-size: 1.3em;
    font-weight: 700;
  }


.mvn_footer {
  background-color: $light-gray;
  color: $maven;
  width:100%;
  min-height: 70px;
  padding: 10px;
  margin:0;
  border: solid 1px $light-gray;

   a:link {
    color: $maven;
  }

  a:visited {
    color: $maven;
    font-weight: bold;
  }

  a:hover {
    color: $maven;
    font-weight: bold;
  }

  a:active {
    color: $maven;
  }

  .mvn_copy {
    font-size:.8rem;
    text-align: right;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    li {
      font-size: 0.8rem;
    }


    .copyright {
      margin-right: 0px;
      text-align: left;
      float: left;
    }

    ul {
      display: inline-block;
      float: right;
      li {
            display: inline-block;
      }
    }
  }

   @media screen and (min-width: 40rem) {
    padding: 20px;
    .mvn_copy {
      .copyright {
            margin-right: 100px;
            text-align: left;
            float: left;
      }


    }
   }

}

.announce {
  text-align: center;

  h1 {
        margin-left: 10px;
        padding-top: 20px;
        font-weight: 600;
        line-height: 1em;
        color: $maven;

      }

  img{
    display: block;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }

}

.outline {
  border: solid 2px $orange;
}

.outline_mvn{
  border: solid 2px $maven;
}

.mvn_background{
  background-image: url('https://s3.amazonaws.com/media.smallcubed.com/images/maven/icons/Maven_Icon_512.png');
  opacity: 50%;
}


.spacing {
  background: transparent;
  height: 20px;
}

.spacing.two {
  background: transparent;
  height: 40px;
}

.spacing.three {
  background: transparent;
  height: 60px;
}

.spacing.four {
  background: transparent;
  height: 80px;
}

.spacing.five {
  background: transparent;
  height: 100px;
}

.width75{
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.width80{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.width85{
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.width90{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.width95{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

//******* For markdown **************


img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


img.borderless {
  border:none;
}

img.shadow {
  box-shadow: 2px 2px 20px 10px rgba(0, 0, 0, 0.2);
}


.centered-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}


.charcoal {
  color: $charcoal;
}

.maven {
      color: $maven;
      
    }

.change_type{
    font-weight: 800;
    font-style: italic;
    font-size: 1.2em;
  }

.selected {
  background-color:$maven;
  border-radius: 20px 20px 0 0;
  padding-top: 5px;

  color: #fff;
  a:link {
    color: #fff;
  }

  a:visited {
    color: #fff;
  }

  a:hover {
    color: #fff;
  }

  a:active {
    color: #fff;
  }
}

.pad5 {
  border-radius: 20px 20px 0 0;
  padding-top: 5px;
}
 
 //*******************************
 //Pricing
.mm {
  color: $maven;
  font-weight: 600;
}
.sc_pricing_list li {
  margin-left: 2.5rem;
}

.sc_pricing{
  padding: 0;
  width:100%;
  margin: 0;

  .sc_price_outline_bl{
    border:none;
  }


  .sc_pricing_header{
    padding:10px;
    width:100%;
    text-align: center;
    font-size:1.6rem;
    font-weight: 700;
    color: $jet;
  }

  .sc_purchase_price{
    padding:10px;
    width:100%;
    height: 2.1em;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.6rem;
    color: $maven;
  }

  .sc_display_price{
    padding:10px;
    width:100%;
    height: 2.1em;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.6rem;
    color: $maven;
  }

  .key_text {
    font-family: "Courier"
  }  

  .sc_pricing_content{
    padding: 40px;

    h2{
      font-size: 1.9rem;
    }

    p {
    font-size: 1.0rem;
    color: $jet;
  }

    .refund{
      font-size: 0.8em;
      color: $jet;
      text-align: center;
        
        a {
          color: $maven;
          font-weight:bolder;
        }
      
      a:hover{
        color: grey;
      }
    }
  }

  button{
    background-color: transparent;
    color:$maven;
    font-size: 1.8rem;
    text-shadow: 0.5px 0.5px;
    line-height: 2.0;
    font-weight: 900;
    height: 100%;
    width:100%;

  }

  .btn_purchase {
    font-size:0.8rem; 
    line-height: 1.1;   
    height: 100%;
    width:50%;
    margin-top:20px;
    margin-bottom:20px;
    margin-right:25%;
    margin-left:25%;
    padding:5px 15px;
    border: solid 2px $jet;
    border-radius: 5px;
    position: relative;
    
  }

  .quantity_desc {
    font-size: 0.75em;
    font-family: "Maven Pro";
  }

  .btn_purchase.disable, .btn_purchase.disable:hover {
    color: $medium-gray;
    background-color: $light-gray;
    border: solid 1px $medium-gray;
  }
    
  .btn_purchase:hover {
    background-color: $maven;
    color:#fff;
    border: solid 2px $maven;
  } 

  .btn_hidden {
    display: none;
  }

  .license{
  margin-top:10px;
  margin-left: 10px;
  height:100%;
  font-size: 1.4rem;
  line-height: 2.4;  

    input{
      margin:20px 0 0 0;
      padding:15px 10px;
      width:95%;
      border: solid 2px $scblue;
      border-radius:5px;
      font-size:1rem;
    }

    input:focus {
      border: solid 2px $scblue;
      background-color: transparent;
      outline: none;
      box-shadow:none;

    }

    .btn_renewal{
      margin:20px 0 0 0;
      padding:8px 15px;
      font-weight: bold;
      font-size:0.9rem; 
      color:#fff;
      text-shadow: 0.5px 0.5px;
      background-color: $maven;
      border: solid 1px $maven;
      border-radius:5px;
      width:95%;
      
      button{
        color:#fff;
      }
      
    }
    
    .hidden {
      display: none;
    }
    
    #add_user_count {
      margin-bottom: -10px;
      
      p {
        margin: 0px;
      }
      
      #count_change {
        padding: 0px;
        padding-left: 0.25em;
        margin: 0px;
        display: inline-block;
        max-width: 70px;
        float: initial;
        text-align: center;
        border-color: $maven;
      }
    }

    #action_choice {
      p {
        text-align: center;
        font-size: 0.6em;
        margin-top: 0px;
        margin-bottom: -10px;
      }
    }


    .btn_renewal:hover{
      background-color: $maven;
      opacity: .8;
      color:#fff;
      border: solid 1px $maven;
    }
    
    #expiration_date {
      margin-bottom: 7px;
    }
  
  #coupon_info {
    font-size: 15px;
    font-weight: bold;
  }
  
  .renewal_details p {
    font-size: 0.9rem;
    line-height: 2.0;
    margin: 5.0px;
  }
  }

  .sc_renewal_price{
    margin-top:-1em;
    padding-bottom: 3em;
    width:100%;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:600;
    font-size:1.1rem;
    line-height:1.2;
    color: $maven;
  }

  p {
    padding:0;
    margin-top:7px;
    border:none;
    line-height: 2.2;
    font-size: 0.8em;
    width:100%;
    color: $scblue;
  }

  a:hover{
    color:$jet;
    font-weight: bolder;
    }


  .license_info {
      padding:10px;
      text-align: center;
      border: solid 2px $scblue;
      border-radius: 5px;
      width:100%;
  }

  #qualify-result {
    color: $alert-color;
    text-shadow: 0.5px 0.5px;
    font-weight: bolder;
  }

  .alert {
    color: $alert-color;
  }
  .warn {
    color: $orange1;
  }
  .info {
    color: #2965ae;
    font-weight:bolder;
    text-shadow: 0.5px 0.5px;

  }

  @media screen and (min-width: 45rem){
  font-size:1.5rem;
    
    .sc_price_outline_bl{
      border: solid 2px $scblue;
    }
    .sc_pricing_header{
      text-align: left;
      font-size:1.8rem;
      float:left;
    }
    .sc_purchase_price{
        font-size:2rem;
       }
     .sc_display_price{
        font-size:2rem;
       }  
    .btn_purchase{
      font-size:1.4rem; 
      line-height: 1.5;  
      padding: 15px 10px;
    }
    .license{
      input{
        width:66%;
        float:left;
        font-size:1.4rem;
      }
    
      .btn_renewal{
        width:33%;
        font-size:1.1rem;
        margin-left: 5px;
      }

      .sc_renewal_price{
    margin-top:0;
    padding-bottom: 2em;
      }
    }
  }
}

.section-header{
  margin: 100px 100px 50px 100px;

  h1{
    font-size: 3.5rem;
    font-weight: 600;
  }
  @media screen and (min-width: 45rem){
  margin: 60px 100px;
  }  

}

.section-header_mm{
  margin: 25px 100px 40px 100px;

  h1{
    font-size: 3.5rem;
    font-weight: 600;
    color: $jet;
  }
  @media screen and (min-width: 45rem){
  margin: 25px 100px;
  }  

}

.zoom90 {
          zoom: 0.90; /* all browsers */
         -moz-transform: scale(0.90); /* Firefox */
}

.zoom80 {
          zoom: 0.80; /* all browsers */
         -moz-transform: scale(0.80); /* Firefox */
}


.zoom75 {
          zoom: 0.75; /* all browsers */
         -moz-transform: scale(0.75); /* Firefox */
}


.lds-ellipsis {
  display: inline-block;
  position: absolute;
  margin-top: -22px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $medium-gray;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}



